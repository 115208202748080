import { useMemo } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, message } from 'antd';
import { getContrast } from 'utils';
import { removeDuplicatesByKeys } from 'utils';

import { generateClientsMarkers } from 'components/containers/MapRouting/utils';
import { generateSellerMarker } from 'components/containers/MapRouting/utils';
import { hasLocation } from 'components/containers/MapRouting/utils';
import CustomEmpty from 'components/elements/CustomEmpty';

import './style.css';

export const LegendSellersMenu = ({
  selectedSellers = {},
  setSelectedSellers,
  routeSeller,
  resetRoutes = () => {},
  onResetColors = () => {},
  sellers: sellersInfo = [],
}) => {
  const directions = useMemo(() => {
    let allDirections = [];

    Object.values(routeSeller).forEach((values) => {
      const { directionsMap: directionsItems = [] } = values;
      const visibleDirections = directionsItems.filter((direction) => direction.visible);
      allDirections = [...allDirections, ...visibleDirections];
    });

    return allDirections;
  }, [routeSeller]);

  const sellers = useMemo(() => {
    return Object.entries(routeSeller || {});
  }, [routeSeller]);

  const onChangeVisibilityMarker = (seller_code) => {
    const isChecked = selectedSellers[seller_code];
    const group = `seller-${seller_code}`;

    let markers = [];

    if (isChecked) {
      setSelectedSellers(seller_code, null);
      return;
    }

    const {
      inRouteClients = [],
      outRouteClients: outRouteMarkers = [],
      color,
      index,
    } = routeSeller[seller_code] || {};

    const seller = sellersInfo.find((seller) => seller.seller_code === seller_code);
    if (seller && hasLocation(seller?.location)) {
      const markerSeller = {
        ...generateSellerMarker(seller),
        group: seller_code,
      };
      markers = [...markers, markerSeller];
    }

    const label = (index + 1).toString();
    const inRouteMarker = generateClientsMarkers({
      clients: inRouteClients,
      color,
      label,
      inRoute: true,
    });

    markers = [
      ...markers,
      ...inRouteMarker.map((inRouteMarker) => ({
        ...inRouteMarker,
        group,
      })),
      ...outRouteMarkers.map((outRouteMarker) => ({
        ...outRouteMarker,
        icon: { ...outRouteMarker['icon'], letter: 'F', color: '#000' },
        group,
      })),
    ];

    const distinctMarkers = removeDuplicatesByKeys(markers, 'id');
    setSelectedSellers(seller_code, distinctMarkers);
  };

  return (
    <div className='itinerary-list-container'>
      <h1 className='header-menu'>Clientes</h1>
      <div className='legend-seller-menu-content'>
        {Boolean(directions.length) && (
          <Alert
            message='Remova os roteiros do mapa para melhorar a visualização dos clientes.'
            type='info'
            action={
              <Button className='custom-button' size='small' type='primary' onClick={resetRoutes}>
                Remover
              </Button>
            }
          />
        )}
        {!sellers.length ? (
          <CustomEmpty description='Não encontramos nenhum vendedor.' context={'table'} />
        ) : (
          <>
            <span className='button-reload-wrapper'>
              <Button size='large' className='custom-button' type='link' onClick={onResetColors}>
                Recarregar cores
                <SyncOutlined />
              </Button>
            </span>
            {sellers.map(([key, values]) => {
              const {
                seller_name,
                color,
                index,
                outRouteClients = [],
                inRouteClients = [],
              } = values;
              const background = color;
              const textColor = `${getContrast(background)}`;
              const checked = Boolean(selectedSellers[key]);
              const hasClients = (outRouteClients?.length || 0) + (inRouteClients?.length || 0) > 0;

              return (
                <div key={key}>
                  <Checkbox
                    className={'custom-checkbox'}
                    checked={checked}
                    onChange={() => {
                      if (hasClients) {
                        onChangeVisibilityMarker(key);
                      } else {
                        message.warn('Esse vendedor não têm clientes para exibir.');
                      }
                    }}
                    id={'marker-' + key}
                    name={'marker-' + key}
                  >
                    <span className='legend-map-checkbox-content'>
                      <div
                        style={{
                          background,
                          color: textColor,
                        }}
                      >
                        {index + 1}
                      </div>
                      {seller_name}
                    </span>
                  </Checkbox>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default LegendSellersMenu;
