import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { message, Modal, Spin } from 'antd';
import { max_checked_keys } from 'constants/index';
import { groupBy } from 'lodash';
import moment from 'moment';

import { RoteirizzeMap } from 'components/elements';
import { CloseIcon } from 'components/elements/Icon/customIcons';

import { getRandomHexColor, parseMapData } from './utils';

import './style.css';

const MapRouting = ({
  data = [],
  sellers = [],
  routingOffCourse = [],
  sellersResume = [],
  companyCode = '',
  waypoints = {},
  allowWalkingMode = false,
}) => {
  const [resetMap, setResetMap] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedSellers, setSelectedSellers] = useState({});
  const [routeSeller, setRouteSeller] = useState({});
  const [selectedOptionMenu, setSelectedOptionMenu] = useState(null);

  const checkedKeys = useMemo(() => {
    return Object.values(routeSeller || {})
      .map((data) => data.checkedKeys)
      .flat()
      .filter((value) => {
        const [weekly, day, seller_code] = value.split('_');
        return weekly && day && seller_code;
      });
  }, [routeSeller]);

  const lenCheckedKeys = useMemo(() => {
    return checkedKeys.length;
  }, [checkedKeys.length]);

  const markers = useMemo(() => {
    let allMarkers = [];

    Object.values(routeSeller).forEach((values) => {
      const { showOffRouting, outRouteClients } = values;
      if (showOffRouting) {
        allMarkers = [...allMarkers, ...outRouteClients];
      }
    });

    const clientsBySeller = Object.values(selectedSellers || {});
    if (clientsBySeller.length) {
      const flattedArray = clientsBySeller.flat();
      allMarkers = [...allMarkers, ...flattedArray];
    }

    return allMarkers;
  }, [routeSeller, selectedSellers]);

  const directions = useMemo(() => {
    let allDirections = [];

    Object.values(routeSeller).forEach((values) => {
      const { directionsMap: directionsItems = [] } = values;
      const visibleDirections = directionsItems.filter((direction) => direction.visible);
      allDirections = [...allDirections, ...visibleDirections];
    });

    return allDirections;
  }, [routeSeller]);

  const sellersGroup = useMemo(() => groupBy(data, 'seller_code'), [data]);

  const onClickShowReason = useCallback((reasons) => {
    setModalData(reasons || {});
    setShowReason(true);
  }, []);

  const handleParseMapData = useCallback(() => {
    const dataBySeller = {};

    sellers.forEach((seller, index) => {
      const { seller_code } = seller;
      const { foraRotas, treeData, directionsItems, findedSellerInSellerInfo } = parseMapData({
        customers: sellersGroup[seller_code] || [],
        seller,
        sellers,
        routingOffCourse: routingOffCourse.filter(
          (customer) => customer.seller_code === seller_code,
        ),
        onClickShowReason,
        allowWalkingMode,
      });

      dataBySeller[seller_code] = {
        showOffRouting: false,
        outRouteClients: foraRotas || [],
        inRouteClients: sellersGroup[seller_code] || [],
        weeklyTree: treeData || [],
        checkedKeys: [],
        directionsMap: directionsItems || [],
        showSellerPin: findedSellerInSellerInfo || false,
        seller_name: seller.name,
        seller_code,
        color: getRandomHexColor(),
        index,
      };
    });

    setRouteSeller(dataBySeller);
  }, [allowWalkingMode, onClickShowReason, routingOffCourse, sellers, sellersGroup]);

  const onResetColors = useCallback(() => {
    setResetMap(true);

    setRouteSeller((prevRouteSeller) => {
      Object.keys(prevRouteSeller || {}).forEach((key) => {
        const newColor = getRandomHexColor();
        prevRouteSeller[key]['color'] = newColor;
      });

      setSelectedSellers((prevSelectedSellers) => {
        Object.entries(prevSelectedSellers || {}).forEach(([seller_code, markers]) => {
          prevSelectedSellers[seller_code] = markers.map((marker) => ({
            ...marker,
            icon: {
              ...marker.icon,
              color:
                marker.id !== seller_code
                  ? prevRouteSeller[seller_code]['color']
                  : marker.icon.color,
            },
          }));
        });

        return { ...prevSelectedSellers };
      });

      return { ...prevRouteSeller };
    });

    setTimeout(() => {
      setResetMap(false);
    }, 100);
  }, []);

  const setShowOffRouting = useCallback((seller_code) => {
    setRouteSeller((prev) => {
      if (seller_code in prev) {
        prev[seller_code]['showOffRouting'] = !prev[seller_code]['showOffRouting'];
      }

      return { ...prev };
    });
  }, []);

  const setShowClientClusters = useCallback((seller_code) => {
    setRouteSeller((prev) => {
      if (seller_code in prev) {
        prev[seller_code]['showClientClusters'] = !prev[seller_code]['showClientClusters'];
      }

      return { ...prev };
    });
  }, []);

  const setDirections = useCallback((callback, seller_code) => {
    setRouteSeller((prev) => {
      const sellerDirections =
        Object.values(prev).find((seller) => seller.seller_code === seller_code)?.directionsMap ||
        [];
      const newDirections = callback?.(sellerDirections) || prev;
      prev[seller_code]['directionsMap'] = newDirections;
      return { ...prev };
    });
  }, []);

  const setCheckedKeys = useCallback((newCheckedKeys, seller_code) => {
    setRouteSeller((prev) => {
      const lastValue = prev[seller_code]['checkedKeys'];
      prev[seller_code]['checkedKeys'] = newCheckedKeys || [];

      if (
        Object.values(prev || {})
          .map((data) => data.checkedKeys)
          .flat()
          .filter((value) => {
            const [weekly, day, seller_code] = value.split('_');
            return weekly && day && seller_code;
          }).length > max_checked_keys
      ) {
        message.warn('Limite de ' + max_checked_keys + ' rotas selecionadas foi atingido');
        prev[seller_code]['checkedKeys'] = lastValue;
        return prev;
      }

      return { ...prev };
    });
  }, []);

  const resetRoutes = useCallback(() => {
    setRouteSeller((prev) => {
      Object.entries(prev || {}).forEach(([key, values]) => {
        prev[key]['checkedKeys'] = [];
        prev[key]['directionsMap'] = values['directionsMap'].map((direction) => ({
          ...direction,
          visible: false,
        }));
      });

      return { ...prev };
    });
  }, []);

  const resetSelectedSellers = useCallback(() => {
    setSelectedSellers({});
  }, []);

  const handleSelectedSellers = (seller_code, markers) => {
    setSelectedSellers((prev) => {
      if (!markers?.length && seller_code in prev) {
        delete prev[seller_code];
      } else {
        prev[seller_code] = markers;
      }

      return { ...prev };
    });
  };

  useEffect(() => {
    handleParseMapData();
  }, [handleParseMapData]);

  return (
    <>
      {!resetMap ? (
        <RoteirizzeMap
          height={'calc(100vh - 85px)'}
          allowWalkingMode={allowWalkingMode}
          showConfigMenu
          showClientClusters
          waypoints={waypoints}
          markers={markers}
          directions={directions}
          dataComponents={{
            selected: selectedOptionMenu,
            checkedKeys,
            lenCheckedKeys,
            companyCode,
            sellers,
            sellersGroup,
            sellers_view: data,
            sellersResume,
            routeSeller,
            selectedSellers,
            onResetColors,
            setSelectedSellers: handleSelectedSellers,
            setShowClientClusters,
            setShowOffRouting,
            setDirections,
            setCheckedKeys,
            resetRoutes,
            resetSelectedSellers,
            setSelected: setSelectedOptionMenu,
          }}
        />
      ) : (
        <Spin size='large' tip='Recarregando mapa...'>
          <div style={{ minHeight: '90vh' }}></div>{' '}
        </Spin>
      )}

      <Modal
        className='custom-modal'
        title='Motivo(s)'
        centered
        destroyOnClose={true}
        closable={true}
        open={showReason}
        width={800}
        closeIcon={<CloseIcon />}
        onCancel={() => {
          setShowReason(false);
        }}
        footer={null}
      >
        {Object.keys(modalData).map((week) => {
          const weeks = modalData[week];
          return (
            <div key={week} style={{ marginBottom: 10 }}>
              <span className='reason-week'>{`Semana ${week}`}</span>
              {Object.keys(weeks).map((days) => {
                const item = weeks[days];
                const date = moment();
                date.day(item.dia);

                const formattedDate = date.format('dddd');

                return (
                  <div key={`reason-${week}`} className='reason-day-box'>
                    <span style={{ fontWeight: 600 }}>{formattedDate}</span>
                    <span style={{ color: '#4f4f4f' }}>{item.motivo}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Modal>
    </>
  );
};

export default MapRouting;
